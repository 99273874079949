import { lazy, Suspense } from 'react';

import { RouteLayout } from 'shared/components/layouts';
import { FeedSkeleton } from 'shared/components/ui';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';
import { useResponsive } from 'shared/hooks';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const CollectiveIntelligenceContent = lazy(() => import('../components/CollectiveIntelligence'));

export function CollectiveIntelligencePage() {
  const { isTablet } = useResponsive();

  return (
    <SearchProvider>
      <RouteLayout
        withMaxWidth
        content={
          <Suspense fallback={<FeedSkeleton />}>
            <CollectiveIntelligenceContent />
          </Suspense>
        }
        sidebar={
          !isTablet && (
            <Suspense fallback={<FeedSkeleton />}>
              <CommunitySidebar items={[DISCOUNTS, SUPPORTERS]} />
            </Suspense>
          )
        }
      />
    </SearchProvider>
  );
}

import { useMemo } from 'react';
import { Business } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';

import { OrganizationItemFragment } from 'shared/graphql';
import { generateOrganizationRoute } from 'shared/routes';

import { AuthorAvatar, AuthorAvatarProps } from './AuthorAvatar';

interface Props {
  organization: OrganizationItemFragment | undefined | null;
  iconSize?: SvgIconProps['fontSize'];

  disabled?: boolean;
  variant?: AuthorAvatarProps['variant'];

  className?: string;
}

export function OrganizationAvatar(props: Props) {
  const source = props.organization?.logo?.downloadUrl ?? undefined;
  const name = props.organization?.name;

  const route = useMemo(() => {
    return generateOrganizationRoute(props.organization?.id);
  }, [props.organization]);

  return (
    <AuthorAvatar
      className={props.className}
      source={source}
      name={name}
      route={route}
      variant={props.variant}
      disabled={!props.organization || props.disabled}
    >
      <Business fontSize={props.iconSize} />
    </AuthorAvatar>
  );
}

import { AvatarProps, Skeleton } from '@mui/material';
import { FileValue } from '@8base-react/file-input';

import { ONE_MEGABYTE } from 'shared/constants/files';

import { FileInputWrap as FileInput } from '../FileInputWrap';
import { StyledContainer, StyledIcon } from './styles';

const MAX_FILE_SIZE = ONE_MEGABYTE * 10;

export interface AvatarImageInputProps extends Omit<AvatarProps, 'onChange'> {
  onChange: (value: FileValue) => void;
  loading?: boolean;
}

export function AvatarImageInput({ onChange, loading, ...props }: AvatarImageInputProps) {
  const onPickImage = (pick: (options: {}) => Promise<unknown>) => {
    // picker spamming his script into DOM on every pick
    document.getElementById('fs-loader-picker')?.remove();

    pick({
      accept: 'image/*',
      fromSources: ['local_file_system'],
      maxSize: MAX_FILE_SIZE,
    });
  };

  const handleChange = (resultFile: FileValue | FileValue[]) => {
    if (!resultFile || Array.isArray(resultFile)) {
      return;
    }

    onChange(resultFile);
  };

  if (loading) {
    return (
      <Skeleton>
        <StyledContainer {...props}>
          <StyledIcon />
        </StyledContainer>
      </Skeleton>
    );
  }

  return (
    <FileInput maxFiles={1} onChange={handleChange}>
      {({ pick }) => {
        return (
          <StyledContainer {...props} onClick={() => onPickImage(pick)}>
            <StyledIcon />
          </StyledContainer>
        );
      }}
    </FileInput>
  );
}

import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';
import { FileUpload } from '@mui/icons-material';

export const StyledIcon = styled(FileUpload)`
  position: absolute;
  z-index: 2;
  margin: auto;
  color: white;
  opacity: 0;
`;

export const StyledContainer = styled(Avatar)``;

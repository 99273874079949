import { Fragment, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  Avatar,
  Box,
  capitalize,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { useHistory, useLocation, Switch, Route, generatePath } from 'react-router-dom';
import { Edit, Forum, MoreVert } from '@mui/icons-material';

import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

import { extractYear, formatExternalLink, processFilestackUrlSrcSet } from '@jebel/utils';
import { SelectOption, USER_AFFILIATIONS } from '@jebel/constants';

import {
  SettingsPublicProfileSectionsEducation,
  SettingsPublicProfileSectionsExperience,
} from 'features/settings/components/SettingsPublicProfileSections';
import { Typography, Description, PopoverV2 } from 'shared/components/ui';
import { ROUTES, USER_ACTIVITIES_OPTIONS } from 'shared/constants';
import {
  EducationProfileFragment,
  GroupDetailsFragment,
  ProfessionalProfileFragment,
  User,
  UserPublicProfileFragment,
} from 'shared/graphql';
import { useInboxContext } from 'providers/InboxProvider/useInbox';
import { useCurrentUser, useResponsive } from 'shared/hooks';
import { formatUserName } from 'shared/utils/user';

import { ExplorerNationProfilePostsList } from './ExplorerNationProfilePostsList';
import { ExplorerNationProfileSidebar } from './ExplorerNationProfileSidebar';

const AUTHOR_AVATAR_SIZE = 120;

const listIconCSS = css`
  margin-right: 0.5rem;
`;

const avatarCss = (isMobile: boolean) => theme =>
  css`
    width: ${isMobile ? AUTHOR_AVATAR_SIZE / 1.5 : AUTHOR_AVATAR_SIZE}px;
    height: ${isMobile ? AUTHOR_AVATAR_SIZE / 1.5 : AUTHOR_AVATAR_SIZE}px;
    margin-right: ${theme.spacing(3)}px;
  `;

const profileNameCss = (isMobile: boolean) => theme =>
  css`
    ${isMobile
      ? `font-size: ${theme.typography.fontSize * 1.5}px; line-height: 16px; font-weight: 400;`
      : `font-size: ${theme.typography.fontSize * 2}px;`}
    margin-right: ${theme.spacing(2)}px;
  `;

const hobbiesBoxCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  border-radius: ${theme.spacing(1)}px;
  grid-gap: ${theme.spacing(2)}px;

  ${theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
  }
`;

const hobbyItemCss = (theme: Theme) => css`
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  text-transform: capitalize;

  &::marker {
    color: ${theme.palette.secondary.light};
  }
`;

const nameAndEmploymentCss = (theme: Theme) =>
  css`
    display: flex;

    ${theme.breakpoints.down('lg')} {
      display: flex;
      flex-direction: column;
      grid-gap: ${theme.spacing(1)}px;
    }

    ${theme.breakpoints.down('md')} {
      display: flex;
      flex-direction: column;
      grid-gap: ${theme.spacing(1)}px;
    }
  `;

interface Props {
  user: UserPublicProfileFragment;
  joinedGroups: GroupDetailsFragment[];
  professionalExperience: ProfessionalProfileFragment[];
  professionalEducation: EducationProfileFragment[];
}

export function ExplorerNationProfileContent({
  user,
  joinedGroups,
  professionalEducation,
  professionalExperience,
}: Props) {
  const { isMobile } = useResponsive();
  const { userId } = useCurrentUser();
  const { onOpenInboxModal } = useInboxContext();
  const { pathname } = useLocation();
  const { push: navigate } = useHistory();

  const formattedName = formatUserName(user);
  const isSeekingEmployment = Boolean(user?.isSeekingEmployment);
  const isOwnProfile = user?.id === userId;
  const showDropdown = isOwnProfile;

  const [ownedOrganization] = user?.ownedOrganizations?.items ?? [];

  const profileSectionRoute = useMemo(() => {
    return generatePath(ROUTES.user.explorer.profile, { id: user?.id as string });
  }, [user]);

  const feedSectionRoute = useMemo(() => {
    return generatePath(ROUTES.user.explorer.profilePosts, { id: user?.id as string });
  }, [user]);

  const openFeedSection = useCallback(() => {
    if (!user?.id) {
      return;
    }

    navigate(feedSectionRoute);
  }, [feedSectionRoute, navigate, user?.id]);

  const openProfleSection = useCallback(() => {
    if (!user?.id) {
      return;
    }

    navigate(profileSectionRoute);
  }, [profileSectionRoute, navigate, user?.id]);

  const handleMessage = useCallback(() => {
    if (!onOpenInboxModal) {
      return;
    }

    onOpenInboxModal({
      isOpen: true,
      options: {
        members: [user as User],
        messageType: 'personal',
      },
    });
  }, [onOpenInboxModal, user]);

  const openEditProfile = () => {
    navigate(ROUTES.user.settings.profile);
  };

  const hobbies = useMemo(() => {
    const hobbies = user?.hobbies ?? [];

    const options = hobbies
      .filter((hobby): hobby is string => typeof hobby === 'string')
      .map(hobby => USER_ACTIVITIES_OPTIONS.hobbies.find(curr => curr.value === hobby))
      .filter(Boolean);

    return options as SelectOption[];
  }, [user]);

  const clubs = useMemo(() => {
    const clubs = user?.activityClubs ?? [];

    const options = clubs
      .filter((hobby): hobby is string => typeof hobby === 'string')
      .map(hobby => USER_ACTIVITIES_OPTIONS.clubs.find(curr => curr.value === hobby))
      .filter(Boolean);

    return options as SelectOption[];
  }, [user]);

  const isProfileEmpty =
    hobbies.length === 0 &&
    clubs.length === 0 &&
    professionalEducation.length === 0 &&
    professionalExperience.length === 0;

  return (
    <Box display="flex" flexDirection="column" gridGap="1.5rem">
      <Box display="flex" flexDirection="row">
        <Avatar
          css={avatarCss(isMobile)}
          src={user?.avatar?.downloadUrl || ''}
          srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
            compress: true,
            resize: {
              width: AUTHOR_AVATAR_SIZE,
              height: AUTHOR_AVATAR_SIZE,
              fit: 'crop',
            },
          })}
        />

        <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={1}>
              <Box css={nameAndEmploymentCss}>
                <Typography css={profileNameCss(isMobile)} variant="subtitle3">
                  {formattedName}
                </Typography>
              </Box>

              {showDropdown && (
                <PopoverV2
                  triggerType="icon-button"
                  tripperProps={{
                    children: <MoreVert />,
                  }}
                  popoverProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                >
                  <List disablePadding>
                    {isOwnProfile && (
                      <ListItem button onClick={openEditProfile}>
                        <Edit color="disabled" css={listIconCSS} />
                        <ListItemText primary="Edit profile" />
                      </ListItem>
                    )}
                  </List>
                </PopoverV2>
              )}
            </Box>

            <Box display="flex" alignItems="center" gridGap="0.5rem" flexWrap="wrap">
              <Chip
                size="small"
                color="secondary"
                label={capitalize(user?.affiliation ?? USER_AFFILIATIONS.other)}
              />

              {isSeekingEmployment && (
                <Chip size="small" color="primary" label="Seeking employment" />
              )}

              {user?.graduatingYear && (
                <Fragment>
                  <Typography color="textSecondary" variant="body2">
                    Class of {extractYear(user?.graduatingYear)}
                  </Typography>
                </Fragment>
              )}
            </Box>
          </Box>

          {!isMobile && (
            <Box display="flex" justifyContent="space-between">
              <Typography color="textSecondary">
                {ownedOrganization?.name && `${ownedOrganization?.name}, `}
                {capitalize(user?.currentIndustry || '')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {isMobile && (
        <Box display="flex" justifyContent="space-between">
          <Typography color="textSecondary">
            {ownedOrganization?.name && `${ownedOrganization?.name}, `}
            {capitalize(user?.currentIndustry || '')}
          </Typography>
        </Box>
      )}

      <Box display="flex" alignItems="center" gridGap="1rem">
        <Tooltip title="Message">
          <IconButton color="primary" onClick={handleMessage}>
            <Forum />
          </IconButton>
        </Tooltip>

        {Boolean(user.facebookLink) && (
          <Tooltip title="Facebook">
            <IconButton
              color="primary"
              target="_blank"
              href={formatExternalLink(user.facebookLink)}
            >
              <Facebook />
            </IconButton>
          </Tooltip>
        )}

        {Boolean(user.instagramLink) && (
          <Tooltip title="Instagram">
            <IconButton
              color="primary"
              target="_blank"
              href={formatExternalLink(user.instagramLink)}
            >
              <Instagram />
            </IconButton>
          </Tooltip>
        )}

        {Boolean(user.linkedInLink) && (
          <Tooltip title="LinkedIn">
            <IconButton
              color="primary"
              target="_blank"
              href={formatExternalLink(user.linkedInLink)}
            >
              <LinkedIn />
            </IconButton>
          </Tooltip>
        )}

        {Boolean(user.twitterLink) && (
          <Tooltip title="X (formerly Twitter)">
            <IconButton color="primary" target="_blank" href={formatExternalLink(user.twitterLink)}>
              <XIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider />

      {!!user?.description && (
        <Fragment>
          <Typography variant="h6">About me</Typography>
          <Description description={user?.description || ''} />
        </Fragment>
      )}

      {!!user?.professionalDesignations && (
        <Fragment>
          <Typography variant="h6">Professional Designations</Typography>
          <Typography variant="subtitle5">{user.professionalDesignations}</Typography>
        </Fragment>
      )}

      <Tabs centered variant="fullWidth" value={pathname}>
        <Tab label="Profile" value={profileSectionRoute} onClick={openProfleSection} />
        <Tab label="Posts" value={feedSectionRoute} onClick={openFeedSection} />
      </Tabs>

      <Switch>
        <Route exact path={ROUTES.user.explorer.profile}>
          {professionalExperience.length > 0 && (
            <SettingsPublicProfileSectionsExperience data={professionalExperience} />
          )}

          {professionalEducation.length > 0 && (
            <SettingsPublicProfileSectionsEducation educationProfile={professionalEducation} />
          )}

          {hobbies.length > 0 && (
            <Fragment>
              <Typography variant="h6">Hobbies & Interests</Typography>

              <Box css={hobbiesBoxCSS}>
                {hobbies.map(hobby => (
                  <Typography css={hobbyItemCss} key={hobby.value}>
                    {hobby.label}
                  </Typography>
                ))}
              </Box>
            </Fragment>
          )}

          {clubs.length > 0 && (
            <Fragment>
              <Typography variant="h6">Clubs & Sports</Typography>

              <Box css={hobbiesBoxCSS}>
                {clubs.map(club => (
                  <Typography css={hobbyItemCss} key={club.value}>
                    {club.label}
                  </Typography>
                ))}
              </Box>
            </Fragment>
          )}

          {isProfileEmpty && (
            <Typography align="center">No profile-related content found</Typography>
          )}

          {isMobile && (
            <ExplorerNationProfileSidebar
              organizations={user?.ownedOrganizations?.items}
              groups={joinedGroups}
            />
          )}
        </Route>

        <Route exact path={ROUTES.user.explorer.profilePosts}>
          <ExplorerNationProfilePostsList authorId={user?.id} />
        </Route>
      </Switch>
    </Box>
  );
}

import { RouteLayout } from 'shared/components/layouts';
import { DISCOUNTS, SUPPORTERS } from 'shared/constants';

import { lazy, Suspense } from 'react';
import { FeedSkeleton } from 'shared/components/ui';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const ForumThreadDetailContent = lazy(() => import('../components/CollectiveIntelligenceDetail'));

export function CollectiveIntelligenceDetailPage() {
  return (
    <RouteLayout
      withMaxWidth
      content={
        <Suspense fallback={<FeedSkeleton />}>
          <ForumThreadDetailContent />
        </Suspense>
      }
      sidebar={
        <Suspense fallback={<FeedSkeleton />}>
          <CommunitySidebar items={[SUPPORTERS, DISCOUNTS]} />
        </Suspense>
      }
    />
  );
}

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';

import { LOCAL_STORAGE_SELECTED_SCHOOL } from 'shared/constants';
import { SchoolConfigurationKeyFilter } from 'shared/graphql';
import { useLocalStorageState, useQueryParams, useSchoolConfigurations } from 'shared/hooks';

export interface SchoolProvidedContext {
  /**
   * The currently selected school.
   * If no school is selected, this will be `null`.
   * If a school is selected, this will be an object with the `id` of the selected school.
   */
  selectedSchool: SchoolConfigurationKeyFilter | null;

  /**
   * Select a school by `id`.
   * @param id The `id` of the school to select.
   */
  selectSchool(id: string): void;
}

const SchoolContext = createContext<SchoolProvidedContext>({
  selectedSchool: {},
  selectSchool: () => {},
});

interface Params {
  school: string;
}

function useCreateSchoolContext(): SchoolProvidedContext {
  const [id, setSelected] = useLocalStorageState<string>(LOCAL_STORAGE_SELECTED_SCHOOL);
  const [params] = useQueryParams<Params>();

  const { data: schools, loading: schoolsLoading } = useSchoolConfigurations();

  useLayoutEffect(() => {
    if (params.school) {
      // Select the school from the URL query params.
      selectSchool(params.school);
    }
  }, [params]);

  useEffect(() => {
    if (schoolsLoading || !schools.length) {
      return;
    }

    const [first] = schools;

    if (!first || !first?.id) {
      return;
    }

    const selected = schools.find(school => school.id === id);

    if (!id || !selected) {
      // If no school is selected, select the first school.
      setSelected(first.id);
    }
  }, [id, schools]);

  const selectSchool = (id: string) => {
    setSelected(id);
    // Refresh the page to avoid inconsistent state and permissions.
    window.open('/', '_self');
  };

  const selectedSchool = useMemo(() => (id ? { id } : null), [id]);

  return {
    selectedSchool,
    selectSchool,
  };
}

export function SchoolProvider(props: PropsWithChildren<unknown>) {
  const context = useCreateSchoolContext();

  return <SchoolContext.Provider value={context}>{props.children}</SchoolContext.Provider>;
}

export function useSchoolContext() {
  const context = useContext(SchoolContext);

  if (!context) {
    throw new Error(`"useSchoolContext" must be used within a "SchoolProvider"`);
  }

  return context;
}

import { useMemo } from 'react';
import { useAuth } from '@8base-react/auth';

import { useAppAuth } from 'providers/useAppAuth';
import { useCurrentUserQuery } from 'shared/graphql';
import { recordDebug, recordError } from 'shared/utils/record';
import { useCurrentUserPermissions } from './useCurrentUserPermissions';

export function useCurrentUser() {
  const { isAuthorized } = useAuth();
  const { hasSession, logout } = useAppAuth();
  const { isSchoolAdmin } = useCurrentUserPermissions();

  const canFetch = hasSession && isAuthorized;

  const {
    data: response,
    loading,
    error,
  } = useCurrentUserQuery({
    skip: !canFetch,
    // Poll every minute to keep the session alive.
    pollInterval: 1000 * 60 * 1,

    onError(error) {
      recordDebug('Closing session due no user was found with the current token.');
      recordError(error);
      // Close the current session because not user can be setup.
      logout();
    },
  });

  const user = response?.user;

  /** @deprecated */
  const isBusinessAdminOrManager = useMemo(() => {
    return (
      (user?.managerPreferences?.items.length !== 0 &&
        user?.managerPreferences?.items.some(manager => manager.status === 'active')) ||
      false
    );
  }, [user]);

  return {
    user,
    /** @deprecated Use {@link user} instead. */
    userId: user?.id,
    loading,
    error,
    /** @deprecated Use from `useCurrentUserPermissions`. */
    isAdmin: isSchoolAdmin,
    /** @deprecated */
    isBusinessAdminOrManager,
  };
}

import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/react';

import { sendToSentry } from 'shared/utils/sentry';
import { Button } from 'shared/components/ui';
import { useIntersectionWatcher } from 'shared/hooks';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface Props {
  hasMore: boolean;
  fetching?: boolean;

  /** @deprecated Use `fetchingState` instead. */
  loader?: ReactNode;

  fetchingState?: ReactNode;
  noMoreState?: ReactNode;

  fetchMore(): Promise<void> | void;
}

export function InfiniteScroll(props: PropsWithChildren<Props>) {
  const [fetching, setFetching] = useState(() => props.fetching ?? false);

  const { ref, visible } = useIntersectionWatcher<HTMLDivElement>();

  const fetchingState = props.fetchingState ?? props.loader ?? <FetchingState />;
  const noMoreState = props.noMoreState ?? null;

  const hasMore = props.hasMore ?? true;

  const showNoMore = !hasMore && !fetching && Boolean(noMoreState);
  const showLoadMoreButton = hasMore && !fetching;

  useEffect(() => {
    if (typeof props.fetching === 'boolean') {
      setFetching(props.fetching);
    }
  }, [props.fetching]);

  const changeFetching = useCallback(
    (value: boolean) => {
      if (typeof props.fetching === 'boolean') {
        // Fetching is being controlled outside
        return;
      }

      if (value === fetching) {
        return;
      }

      setFetching(value);
    },
    [setFetching, props.fetching],
  );

  const next = useCallback(async () => {
    if (fetching) {
      return;
    }

    changeFetching(true);

    try {
      await props.fetchMore();
    } catch (err) {
      sendToSentry(err);
    } finally {
      changeFetching(false);
    }
  }, [fetching, props.fetchMore]);

  useEffect(() => {
    if (visible && !fetching && hasMore) {
      next();
    }
  }, [visible, fetching]);

  return (
    <div css={containerCSS}>
      {props.children}

      {fetching ? fetchingState : null}
      {showNoMore ? noMoreState : null}

      {/* This is the element that will be observed */}
      <span ref={ref} />

      {showLoadMoreButton && (
        <Button fullWidth variant="outlined" onClick={next}>
          Show more
        </Button>
      )}
    </div>
  );
}

function FetchingState() {
  return (
    <Typography color="textSecondary" align="center">
      Loading...
    </Typography>
  );
}

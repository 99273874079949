import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip, Badge } from '@material-ui/core';

import { ADMIN_SIDEBAR_NAVIGATION_ITEMS, ROUTES } from 'shared/constants';
import {
  sortExternalLinks,
  useCurrentUser,
  useSchoolConfiguration,
  useSidebarMenuItems,
} from 'shared/hooks';
import { AdminSidebarNavigationItemsList, SidebarExternalLinkItem } from 'shared/types';
import { useInboxUnreadMessagesQuery } from 'shared/graphql';

import { useUnreadSidebarElementsQuery } from '../hooks';
import { SidebarAbout } from './SidebarAbout';
import { SidebarGroups } from './SidebarGroups';
import { useGlobalState } from '../../../../providers';
import {
  Container,
  Content,
  Footer,
  Header,
  Logo,
  NavItem,
  NavItemIcon,
  RectangularSkeleton,
  NavItemText,
  NavList,
  SchoolName,
  ExternalLinkItem,
} from './SidebarNavigation.styles';

export function SidebarNavigation() {
  const { user } = useCurrentUser();
  const { configuration } = useSchoolConfiguration();
  const { sidebarMenuItems, isLoading } = useSidebarMenuItems();
  const { leftMenuDrawer: sidebar = { isOpened: false } } = useGlobalState();
  const { sidebarUnreadElementsList } = useUnreadSidebarElementsQuery();
  const { pathname } = useLocation();

  const { data: unreadMessages } = useInboxUnreadMessagesQuery({
    skip: !user?.id,
    variables: { id: user?.id as string },
    fetchPolicy: 'cache-first',
  });

  const isAdminRoute = useMemo(() => pathname.startsWith('/admin'), [pathname]);

  const unreadMessagesCount = useMemo(() => {
    return unreadMessages?.inboxMessagesList?.count ?? 0;
  }, [unreadMessages]);

  const navigation: AdminSidebarNavigationItemsList = useMemo(() => {
    if (isAdminRoute) {
      return ADMIN_SIDEBAR_NAVIGATION_ITEMS;
    }

    return sidebarMenuItems;
  }, [isAdminRoute, sidebarMenuItems]);

  const externalLinks: SidebarExternalLinkItem[] = useMemo(() => {
    const externals = configuration?.externalLinks?.items ?? [];

    const links = sortExternalLinks(externals.filter(item => item.isActive));

    return links.map(item => ({
      id: item.id as string,
      name: item.name as string,
      link: item.link as string,
    }));
  }, [configuration]);

  const showFooter = Boolean(sidebar?.isOpened);
  const showSidebarGroups = !isAdminRoute && Boolean(sidebar?.isOpened);
  const showExternalLinks = externalLinks.length > 0 && Boolean(sidebar?.isOpened);

  if (isLoading) {
    const items = Array.from({ length: 8 });

    return (
      <Container isOpened={sidebar.isOpened}>
        <RectangularSkeleton height="4rem" />

        <Content>
          {items.map((item, index) => (
            <RectangularSkeleton height="3rem" key={index} />
          ))}
        </Content>

        {showFooter && (
          <Footer>
            <RectangularSkeleton height="2rem" />
          </Footer>
        )}
      </Container>
    );
  }

  return (
    <Container isOpened={sidebar.isOpened}>
      <Header>
        <Logo />

        {sidebar.isOpened && <SchoolName>{configuration?.name}</SchoolName>}
      </Header>

      <Content>
        <NavList>
          {navigation.map(item => {
            const isSelectedRoute = Boolean(pathname.startsWith(item.route));

            const isMailRoute =
              item.route === ROUTES.admin.mail.index || item.route === ROUTES.user.mail.index;

            const badgeCount = isMailRoute
              ? unreadMessagesCount
              : sidebarUnreadElementsList[item.route];

            return (
              <Tooltip
                placement="right"
                key={item.route}
                title={item.name}
                disableHoverListener={sidebar?.isOpened}
                arrow
              >
                <NavItem to={item.route} isSelected={isSelectedRoute}>
                  <Badge
                    max={99}
                    showZero={false}
                    color="secondary"
                    badgeContent={badgeCount}
                    variant={sidebar?.isOpened ? 'standard' : 'dot'}
                    invisible={!badgeCount}
                  >
                    <NavItemIcon name={item.icon} />
                  </Badge>

                  {sidebar?.isOpened && <NavItemText>{item.name}</NavItemText>}
                </NavItem>
              </Tooltip>
            );
          })}

          {showExternalLinks &&
            externalLinks.map(item => (
              <ExternalLinkItem key={item.id} href={item.link} target="_blank">
                <NavItemIcon name="Launch" />
                <NavItemText>{item.name}</NavItemText>
              </ExternalLinkItem>
            ))}
        </NavList>

        {showSidebarGroups && <SidebarGroups />}
      </Content>

      {showFooter && (
        <Footer>
          <SidebarAbout />
        </Footer>
      )}
    </Container>
  );
}
